/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react'
import { EditableComponent } from '@adobe/aem-react-editable-components'
import {
  List as UIFoundationsList,
  Text as UIFoundationsText,
  ListThemes,
  ListTypes,
  ListItemProps
} from '@dcxwam/dcx-wam-ui-foundations'
import { getMarginBottomMappedProp } from '../../../utils/props-mappings'

import ListEditConfig from './ListEditConfig'

const List = ({ ...props }) => {
  const { id, items, type, appliedCssClassNames, marginBottom, ...rest } =
    props.model
  const editableProps = { ...props, model: { ...rest } }

  let listType = ListTypes.None
  switch (type) {
    case 'none':
      listType = ListTypes.None
      break
    case 'ordered':
      listType = ListTypes.Ordered
      break
    case 'unordered':
      listType = ListTypes.Unordered
      break
    case 'image':
      listType = ListTypes.Image
      break
    default:
      break
  }

  const uiListProps = {
    id,
    items: items?.map((item: ListItemProps) => ({
      ...item,
      alt: item?.alt || ''
    })),
    theme:
      appliedCssClassNames === 'cmp-theme--dark'
        ? ListThemes.Dark
        : ListThemes.Light,
    type: listType
  }

  const configSection = {
    hasMarginBottom: getMarginBottomMappedProp(marginBottom)
  }

  return (
    <EditableComponent {...editableProps}>
      {items ? (
        <UIFoundationsText.TextSection {...configSection}>
          <UIFoundationsList {...uiListProps} />
        </UIFoundationsText.TextSection>
      ) : (
        ListEditConfig.emptyLabel
      )}
    </EditableComponent>
  )
}

export default List
